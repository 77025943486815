* {
    margin: 0;
    padding: 0; 
}

html, body {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    height: 100%;
}

a {
    color: rgb(148, 91, 255);
    text-decoration: none;
    outline:none;   /* stop highlighting fragment anchors */
}

hr {
    height: .3em;
    background-color:rgb(65, 67, 70);
    border-radius: 0.45em;
    border: none;
    border-color: rgb(65, 67, 70);
    margin-bottom: 1em;
    margin-top: 1em;
    width: 100%;
}

a:hover {
    text-decoration: underline;
}

body {
    color: rgba(255,255,255,0.8);
    background-color: rgb(37,40,44);
}

.page {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 100%;
    width: 100%;
}

.sidebar {
    height: 100%;
    width: 20%;             /* offset of our sidebar, content's left-margin should match */
    position: fixed;
    text-align: center;
    background-color: rgb(25, 28, 32);
    color: white;
}

.sidebar .title {
    white-space: nowrap;    /* prevent word wrapping title */
}

.sidebar .title a {
    color: rgba(255,255,255,0.8);
    font-size: 100%;
}

.sidebar .title a:hover {
    text-decoration: none;
    color: rgba(255,255,255,0.6);
}

.sidebar .tagline {
    font-size: 80%;
    font-style: italic;
    color:rgba(255,255,255,0.4)
}

.sidebar h3.nav {
    display: inline-flex;
}

.sidebar .nav a {
    text-decoration: none;
    background-color: rgb(24,144,255);
    color: rgb(255,255,255);
    font-size: 80%;
    font-weight: normal;
    border-radius: 2em;
    margin: 0.2em;
    padding: 0.2em 1em;
}

.sidebar .external {
    vertical-align: middle;
    text-align: center;
}

.sidebar .external img {
    padding: 0.5em;
}

.content {
    margin-left: 20%;   /* offset of our sidebar */
    display: flex;
    flex-direction: column;
    /* vertical | horizontal */
    padding: 0em 1em;
    width: 100%;
}

.content h1.title {
    color: rgba(255, 255, 255, .7);
    text-shadow: 0 0 2px black;
}

.content h2.subtitle {
    color: rgba(255, 255, 255, .7);
    text-shadow: 0 0 2px black;
}

.content h2.subtitle a {
    color: rgb(24, 144, 255);
    text-shadow: 0 0 2px black;
}

.content .cardlist .card {
    background-color: rgb(25,28,32);
    margin: 1em 0em;
    padding: 1em;
    border-radius: 0.5em;
}

.content .cardlist .card ul {
    padding: 1em;
}

.content .cardlist .card .title a {
    color: rgb(24, 144, 255);
    font-size: 95%;
    text-decoration: none;
}

.dateline {
    font-style: italic;
}

.tag a {
    text-decoration: none;
    background-color: rgb(15, 119, 19);
    color: white;
    font-size: 80%;
    border-radius: 2em;
    padding: 0.1em 0.5em 0.1em 0.5em;
}

.tag a:hover {
    background-color: rgb(21, 161, 25);
}


.copyright {
    margin-top: 1em;
    color: rgba(255, 255, 255, 0.4);
    font-size: 70%;
    display: inline-block;
    align-self: flex-end;
    text-align: right;
    float: right;
}

.post .footer {
    width:100%;
    text-align: center;
}

.navbutton {
    display: inline-block;
}

.navbutton a {
    font-weight: bold;
    background-color: rgb(165, 165, 165);
    border: rgb(122, 122, 122) 0.1em solid;
    border-radius: 0.4em;
    padding: 0.5em 0.7em 0.5em 0.7em;
    color: black;
    margin-bottom: 1em;
}

.navbutton a:hover {
    background-color: rgb(182, 182, 182);
    text-decoration: none;
}

.previous {
    text-align: left;
    float: left;
}

.next {
    text-align: right;
    float: right;
}

.highlighter-rouge {
    background-color: rgba(255,255,255,0.2);
}

pre { white-space: pre; overflow: auto; }

.anchorlink img {
    display: none;
}

a:hover.anchorlink img {
    display: unset;
    filter: invert(1);
}

/* Use a media query to add a break point at 800px: */
@media screen and (max-width: 800px) {
    .mobilehide {
        display: none;
    }

    /* Change our view be single column with sidebar on top */
    .page {
        display: inline-block;
        flex-direction: unset;
        align-items: unset;
        height: unset;
        width: 100%;
    }
    
    .sidebar {
        height: unset;
        width: 100%;
        position: unset;
    }
    
    .content {
        margin-left: unset;
        display: unset;
        flex-direction: unset;
    }
    /* End single column mobile view change */

    .sidebar .title {
        display: inline-flex;
        font-size: 110%;
    }

    .sidebar .external {
        display: inline-flex;
    }

    .navbutton {
        display: unset;
    }

    .navbutton a {
        display: block;
    }
}